import { EventStorage } from "@/helpers/studentEventStore"
import ws from "@/web_sockets"
import { ref } from "vue"
import { v1 as uuidv1 } from "uuid"
import { createGlobalState } from "@vueuse/core"

// Used to store the last events for debugging purposes only
const lastEvents = ref([])

/**
 * @description This function generates a new UUID v1 token for each browser tab. The token identifies the browser tab and is refreshed on each page reload.
 * @returns {function} A function that returns a new UUID v1 token
 */
const browserTabToken = createGlobalState(uuidv1)

export function useStudentEventRegisterer(quizId, scoreId) {

  ws.channels.QuizStudentEvents.subscribe({ quizId: quizId })
  const eventStorage = new EventStorage(quizId)

  const register = ({ event , exerciseId, details } = { details: {} }) => {
    const detailsWithTabToken = { ...details, browser_tab_token: browserTabToken() }

    lastEvents.value.push({ event, exerciseId, scoreId, details: detailsWithTabToken })

    return eventStorage
      .storeEvent({ type: event, details: detailsWithTabToken, scoreId, exerciseId })
      .then(() => eventStorage.syncAllEvents())
  }

  return {
    register,
    lastEvents,
  }

}
