import { openDB } from "idb"
import ws from "../web_sockets"
import sha256 from "crypto-js/sha256"

const eventStates = {
  CREATED: "created",
  SYNCHRONIZING: "synchronizing",
  SYNCHRONIZED: "synchronized",
}

export class EventStorage {

  constructor(quizId) {
    this.DB_NAME = "evalmee-fs"
    this.STORE_NAME = "flying-squirrel"
    this.DB_VERSION = 1
    this.dbPromise = null
    this.quizId = quizId
  }

  async initializeIndexedDB() {
    const that = this
    if (!this.dbPromise) {
      this.dbPromise = openDB(this.DB_NAME, this.DB_VERSION, {
        upgrade(db) {
          const objectStore = db.createObjectStore(that.STORE_NAME, { keyPath: "id", autoIncrement: true })
          objectStore.createIndex("type", "type", { unique: false })
          objectStore.createIndex("exerciseId", "exerciseId", { unique: false })
          objectStore.createIndex("scoreId", "scoreId", { unique: false })
          objectStore.createIndex("quizId", "quizId", { unique: false })
          objectStore.createIndex("happenedAt", "happenedAt", { unique: false })
          objectStore.createIndex("h", "h", { unique: false })
          objectStore.createIndex("state", "state", { unique: false })
        },
      })
    }

    return this.dbPromise
  }

  async storeEvent({ type, exerciseId, scoreId, details } = { details: {} }) {
    const event = {}
    event.kind = type
    event.details = details
    event.quizId = this.quizId
    event.exerciseId = exerciseId
    event.scoreId = scoreId
    event.happenedAt = new Date()
    event.state = eventStates["CREATED"]

    const db = await this.initializeIndexedDB()

    const tx = db.transaction(this.STORE_NAME, "readwrite")

    event.id = await tx.store.add({ ...event })
    event.h = this.eventHash(event)
    await tx.store.put(event)
    await tx.done

  }

  /**
   * Returns a sha256 hash of the event
   *
   * @param event
   * @return {string}
   */
  eventHash(event) {
    const attributes = {
      index: event.id,
      kind: event.kind,
      quiz_id: event.quizId,
      score_id: event.scoreId,
      exercise_id: event.exerciseId,
      happened_at: Math.floor(event.happenedAt / 1000),
    }

    const nonNullAttributes = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(attributes).filter(([_, v]) => v != null)
    )

    return sha256(JSON.stringify(nonNullAttributes)).toString()
  }

  async deleteEvent(eventId) {
    const db = await this.initializeIndexedDB()

    return db.delete(this.STORE_NAME, eventId)
  }

  async saveEvent(event) {
    const db = await this.initializeIndexedDB()

    return db.put(this.STORE_NAME, event)
  }


  async getAllEvents() {
    const db = await this.initializeIndexedDB()

    return db.getAllFromIndex(this.STORE_NAME, "quizId", this.quizId)
  }

  //same as getAllEvents but with a callbac executed in the transaction
  async getAllEventsWithCallback(callback) {
    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.STORE_NAME, "readwrite")
    const store = tx.store
    const index = store.index("quizId")
    const events = await index.getAll(this.quizId)
    for (const event of events) {
      await callback(event)
    }
    await tx.done
  }

  // same function as syncAllEvents but all the action should be within a trnsaction
  async syncAllEvents() {
    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.STORE_NAME, "readwrite")
    const store = tx.store
    const index = store.index("quizId")
    const events = await index.getAll(this.quizId)
    for (const event of events) {
      // next if event is already being synchronized
      if (event.state === eventStates["SYNCHRONIZING"]) continue

      // Set event status in idb to "sending"
      event.state = eventStates["SYNCHRONIZING"]
      await store.put(event)

      // Send the event to the backend via WebSocket
      await this.syncEvent(event)
        .then((isSent) => {
          if(isSent) return store.delete(event.id)

          // Set event status in idb to "created"
          event.state = eventStates["CREATED"]
          return store.put(event)
        })
    }
    await tx.done

    // return await this.getAllEventsWithCallback(async (event) => {
    //   // next if event is already being synchronized
    //   if (event.state === eventStates["SYNCHRONIZING"]) return
    //
    //   // Set event status in idb to "sending"
    //   event.state = eventStates["SYNCHRONIZING"]
    //   await this.saveEvent(event)
    //
    //   // Send the event to the backend via WebSocket
    //   await this.syncEvent(event)
    //     .then((isSent) => {
    //       if(isSent) return this.deleteEvent(event.id)
    //
    //       // Set event status in idb to "created"
    //       event.state = eventStates["CREATED"]
    //       return this.saveEvent(event)
    //     })
    // })
  }

  async syncEvent(event) {
    return ws.subscriptions?.QuizStudentEvents?.send({
      index: event.id,
      event: event.kind,
      exercise_id: event.exerciseId,
      score_id: event.scoreId,
      happened_at:event.happenedAt ,
      details: event.details,
      hash: event.h,
    })
  }
}
